import * as React from "preact";
import { JSX } from "preact";

export interface IconContext {
  color?: string;
  size?: string;
  class?: string;
  stroke?: string | JSX.SignalLike<string | undefined>; // allow overide stroke color "currentColor"
  fill?: string | JSX.SignalLike<string | undefined>; // allow overide fill color "currentColor"
  strokeWidth?:  number | string | JSX.SignalLike<number | string | undefined>; // allow overide strokeWidth default 0

  style?: JSX.CSSProperties;
  attr?: JSX.SVGAttributes<SVGSVGElement>;
}

export const defaultContext: IconContext = {
  color: undefined,
  size: undefined,
  class: undefined,
  style: undefined,
  attr: undefined,
};

export const defaultIconContext: React.Context<IconContext> = React.createContext && React.createContext(defaultContext);

// denoCacheMetadata={"headers":{"x-cache":"HIT, HIT","cross-origin-resource-policy":"cross-origin","content-type":"text/plain; charset=utf-8","x-jsd-version-type":"version","x-served-by":"cache-fra-etou8220096-FRA, cache-lga21949-LGA","alt-svc":"h3=\":443\"; ma=86400","cf-cache-status":"HIT","date":"Sun, 26 Jan 2025 09:05:40 GMT","cf-ray":"907f57b49b510624-IAD","report-to":"{\"endpoints\":[{\"url\":\"https:\\/\\/a.nel.cloudflare.com\\/report\\/v4?s=p3f66fY7LneXHieCBYKyORT6x1a64083FmsGW1YzwKP42LCNs3I9TWRttj%2BF0I4Tw1ZYp4mttcjZZq8omN1NSFKEUvuPYqdiKrHl232eD%2BbSi5Mfk%2FRIlGelIpMAfOruY5A%3D\"}],\"group\":\"cf-nel\",\"max_age\":604800}","access-control-expose-headers":"*","server":"cloudflare","vary":"Accept-Encoding","cache-control":"public, max-age=31536000, s-maxage=31536000, immutable","access-control-allow-origin":"*","accept-ranges":"bytes","timing-allow-origin":"*","age":"364365","x-jsd-version":"1.0.8","nel":"{\"success_fraction\":0.01,\"report_to\":\"cf-nel\",\"max_age\":604800}","x-content-type-options":"nosniff","etag":"W/\"32a-Bmotoc1mE42JAxp+yFUNnwalsPg\"","strict-transport-security":"max-age=31536000; includeSubDomains; preload"},"url":"https://cdn.jsdelivr.net/gh/urielch/react-icons@1.0.8/lib/iconContext.ts","time":1737882340}