import { type ComponentChildren, type VNode, createElement } from "preact";
import { type JSX } from "preact";
import { defaultIconContext, IconContext } from "./iconContext.ts";


const CAMEL_PROPS =
  /^(?:accent|alignment|arabic|baseline|cap|clip(?!PathU)|color|dominant|fill|flood|font|glyph(?!R)|horiz|image(!S)|letter|lighting|marker(?!H|W|U)|overline|paint|pointer|shape|stop|strikethrough|stroke|text(?!L)|transform|underline|unicode|units|v|vector|vert|word|writing|x(?!C))[A-Z]/;
const CAMEL_REPLACE = /[A-Z0-9]/g;

function filterKebabCase<T extends Record<string, any>>(attrs: T): T {
  const newAttrs: Record<string, any> = {};
  for (const key in attrs) {
    if (key.indexOf('-') === -1 && CAMEL_PROPS.test(key))
      newAttrs[key.replace(CAMEL_REPLACE, '-$&').toLowerCase()] = attrs[key];
    else
      newAttrs[key] = attrs[key];
  }
  return newAttrs as T;
}

/**
 * tree level for SVG
 */
export interface IconTree {
  tag: string;
  attr: { [key: string]: string };
  child?: IconTree[];
}

/**
 * recursivly build internal SVG element
 * @param tree 
 * @returns 
 */
function Tree2Element(tree: IconTree[]): ComponentChildren[] { // React.ReactElement => ComponentChildren
  return (
    tree &&
    tree.map((node, i) =>
      createElement(
        node.tag,
        { key: i, ...filterKebabCase(node.attr) },
        Tree2Element(node.child || []),
      )
    )
  );
}

/**
 * build a SVG componant from an IconTree
 */
export function GenIcon(data: IconTree) {
  return (props: IconBaseProps) => (
    <IconBase attr={{ ...data.attr }} {...props}>
      {Tree2Element(data.child || [])}
    </IconBase>
  );
}

/**
 * attribut for all custom SVG
 */
export interface IconBaseProps extends JSX.SVGAttributes<SVGSVGElement> {
  children?: ComponentChildren; // was React.ReactNode
  size?: number; // was string | number;
  color?: string;
  title?: string;
  class?: string; // new
}

export type IconType = (props: IconBaseProps) => VNode<JSX.SVGAttributes>;

/**
 * build outer SVG element
 */
export function IconBase(
  props: IconBaseProps & { attr?: Record<string, string> },
): VNode<JSX.SVGAttributes> {
  const elem = (conf: IconContext) => {
    const { attr, size, title, ...svgProps } = props;
    let clazz = props.class || '';
    const computedSize = size || conf.size || "1em";
    if (conf.class) {
      if (clazz)
        clazz = `${clazz} ${conf.class}`;
      else
        clazz = conf.class;
    }
    let attrs = {
      stroke: conf.stroke || "currentColor",
      fill: conf.fill || "currentColor",
      strokeWidth: conf.strokeWidth || 0,
      class: clazz,
      ...conf.attr,
      ...attr,
      ...svgProps,
      height: computedSize,
      width: computedSize,
    };
    attrs = filterKebabCase(attrs);
    return (
      <svg
        {...attrs}
        style={filterKebabCase({
          color: props.color || conf.color,
          ...conf.style,
          ...(props.style as JSX.CSSProperties),
        })}
        xmlns="http://www.w3.org/2000/svg"
      >
        {title && <title>{title}</title>}
        {props.children}
      </svg>
    );
  };

  return defaultIconContext !== undefined
    ? (
      <defaultIconContext.Consumer>
        {(conf: IconContext) => elem(conf)}
      </defaultIconContext.Consumer>
    )
    : (
      elem(defaultIconContext)
    );
}

// denoCacheMetadata={"headers":{"x-jsd-version-type":"version","x-served-by":"cache-fra-eddf8230175-FRA, cache-lga21930-LGA","etag":"W/\"d09-LoU0DQ7zrOY2n0QBFPdDGHiFoLY\"","cf-ray":"907f57b49b4e0624-IAD","x-jsd-version":"1.0.8","access-control-allow-origin":"*","x-cache":"HIT, MISS","server":"cloudflare","timing-allow-origin":"*","cache-control":"public, max-age=31536000, s-maxage=31536000, immutable","alt-svc":"h3=\":443\"; ma=86400","age":"1022840","access-control-expose-headers":"*","cross-origin-resource-policy":"cross-origin","content-type":"text/plain; charset=utf-8","vary":"Accept-Encoding","cf-cache-status":"HIT","x-content-type-options":"nosniff","accept-ranges":"bytes","report-to":"{\"endpoints\":[{\"url\":\"https:\\/\\/a.nel.cloudflare.com\\/report\\/v4?s=m4zJ6Y8ILq5zD%2FC7fFCa1Q7i90gbjxlHADLwnDZkmqI42Pr5H%2BC5DqL0OiK2hcpka%2FIy9ijMfXroDqXdpn%2F8VvTIKxPVkM%2BZ14BlLN%2FzghuVg8H3i30j7in%2BqYbNRm8VZeE%3D\"}],\"group\":\"cf-nel\",\"max_age\":604800}","strict-transport-security":"max-age=31536000; includeSubDomains; preload","nel":"{\"success_fraction\":0.01,\"report_to\":\"cf-nel\",\"max_age\":604800}","date":"Sun, 26 Jan 2025 09:05:40 GMT"},"url":"https://cdn.jsdelivr.net/gh/urielch/react-icons@1.0.8/lib/iconBase.tsx","time":1737882340}